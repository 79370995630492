import * as React from "react"
import { useState } from "react"
import { ControlType, addPropertyControls } from "framer"

export function CustomToggle({
    checkedColor,
    uncheckedColor,
    initialState,
    onChange,
}) {
    const [checked, setChecked] = useState(initialState)

    const handleToggle = () => {
        const newChecked = !checked
        setChecked(newChecked)
        if (onChange) {
            onChange(newChecked)
        }
    }

    return (
        <div
            onClick={handleToggle}
            style={{
                display: "inline-block",
                padding: "4px",
                cursor: "pointer",
                backgroundColor: checked ? checkedColor : uncheckedColor,
                borderRadius: "25px",
                width: "60px",
                height: "35px",
                position: "relative",
                transition: "background-color 0.2s",
            }}
        >
            <div
                style={{
                    width: "28px",
                    height: "28px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "3px",
                    left: checked ? "28px" : "4px",
                    transition: "left 0.2s",
                }}
            />
        </div>
    )
}

// Define the property controls for the component
addPropertyControls(CustomToggle, {
    checkedColor: {
        type: ControlType.Color,
        title: "Checked Color",
        defaultValue: "#007BFF",
    },
    uncheckedColor: {
        type: ControlType.Color,
        title: "Unchecked Color",
        defaultValue: "#E0E0E0",
    },
    initialState: {
        type: ControlType.Boolean,
        title: "Initial State",
        defaultValue: false,
    },
    onChange: {
        type: ControlType.EventHandler,
        title: "On Change",
    },
})
